<template>
  <main>
    <NavBar />
    <ThankYouContent />
  </main>
  <Footer />
</template>

<script>
import NavBar from "../components/NavBar";
import ThankYouContent from "../components/ThankYouContent";
import Footer from "../components/TheFooter";
export default {
  name: "Thank-you",
  components: {
    NavBar,
    ThankYouContent,
    Footer,
  }
}

window.scrollTo({top: 0, behavior: 'smooth'});
</script>